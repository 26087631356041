.about-project {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0px 200px 50px;
  overflow: auto;

  p {
    font-family: "nocturne_seriflight";
    line-height: 48px;
    margin: 24px 0 0;
  }

  h1 {
    font-family: "nocturne_seriflight_italic";
    font-size: 104px;
    font-weight: normal;
    margin-top: 14px;
    margin-right: -54px;
    text-align: right;
  }

  .main-text {
    font-size: 40px;
  }

  .sub-text {
    text-align: left;
    font-size: 24px;
  }

  .cross-button-wrapper {
    position: fixed;
    top: 35px;
    right: 35px;
  }
}
