.page-photos {
  height: 100%;
  display: flex;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;

  .tag-name {
    position: absolute;
    top: 12px;
    right: 120px;
    font-family: "nocturne_seriflight_italic";
    font-size: 104px;
    pointer-events: none;
  }

  .photos {
    height: 90%;
    font-size: 0;
    min-width: 100%;

    .photos-row {
      display: flex;
      justify-content: center;
    }

    .photo {
      opacity: 0;
      transition: opacity 0.1s linear;
      -webkit-transition: opacity 0.1s linear;

      &--visible {
        opacity: 1;
      }
    }
  }

  .link-button-wrapper__start {
    top: 24px;
    left: 32px;
  }

  .cross-button-wrapper {
    position: absolute;
    top: 24px;
    right: 32px;
  }

  .trumpet {
    border: 1px solid white;
    position: relative;
  }

  .video-player {
    position: relative;
    video {
      max-height: 100%;
      object-fit: cover;
    }

    button {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .play-button {
    top: 50%;
    left: 50%;
    margin-left: -125px;
    margin-top: -56px;
    height: 113px;
    width: 250px;
    position: absolute;
    opacity: 1;
    transition: opacity 0.2s linear;
    -webkit-transition: opacity 0.2s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }

    &--hidden {
      opacity: 0;
    }

    .button {
      border-width: 0;
      background: transparent;
      padding: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -15px;
      margin-top: -28px;
      width: 30px;
      height: 56px;

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }

      .state-hover {
        display: none;
      }

      .state-regular {
        display: block;
      }

      &:hover,
      &--active {
        .state-hover {
          display: block;
        }

        .state-regular {
          display: none;
        }
      }
    }
  }
}
