.cross-button {
  border-width: 0;
  background: transparent;
  padding: 0;
  width: 55px;
  height: 55px;

  .state-hover {
    display: none;
  }

  .state-regular {
    display: block;
  }

  &:hover,
  &--active {
    .state-hover {
      display: block;
    }

    .state-regular {
      display: none;
    }
  }
}
