.page-photo {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .pending {
    $size: 400px;
    position: absolute;
    width: $size;
    height: $size;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    margin-top: -$size/2;
    margin-left: -$size/2;
  }

  .tags {
    position: absolute;
    font-family: "IBM Plex Mono", monospace;
    top: 0;
    left: 2%;
    right: 2%;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    font-size: 24px;

    a {
      text-decoration: none;
    }

    .tag-column {
      position: relative;
      height: 100%;
      min-width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      padding: 20px 0;

      .tag-slot {
        justify-content: center;
        height: 15%;
        display: flex;
        align-items: center;
      }

      .tag--time {
        color: #3b34fe;
      }
    }
  }

  .photo {
    position: relative;
    z-index: 1;
    height: 70%;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  .link-button-wrapper__start {
    top: 24px;
    left: 32px;
  }
}
