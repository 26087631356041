.page-time {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .canvas {
    flex: 1;
    width: 800px;
    margin-top: -360px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .face {
      position: relative;
    }

    img {
      position: absolute;
    }
  }

  .button {
    border-width: 0;
    background: transparent;
    padding: 0;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    .state-hover {
      display: none;
    }

    .state-regular {
      display: block;
    }

    &:hover,
    &--active {
      .state-hover {
        display: block;
      }

      .state-regular {
        display: none;
      }
    }
  }

  .timeline-section {
    width: 1100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .play-reverse-button,
    .play-button {
      width: 30px;
      height: 56px;
    }

    .play-reverse-button {
      transform: rotate(180deg);
    }

    .timeline__column-before {
      position: absolute;
      right: 100%;
      margin-right: 20px;
      top: 0;
      bottom: 0;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .timeline__column-after {
      position: absolute;
      left: 100%;
      margin-left: 20px;
      top: 0;
      bottom: 0;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .timeline {
      height: 6px;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.3);

      .timeline-progress {
        background-color: rgb(255, 255, 255);
        height: 100%;
      }
    }

    .current-year__wrapper {
      position: absolute;
      top: 0;
      right: 56px;
      bottom: 0;
      left: 0;
    }

    .current-year {
      position: absolute;
      bottom: 100%;
      font-size: 24px;
      font-family: "IBM Plex Mono", monospace;
      pointer-events: none;

      &__background {
        position: absolute;
        left: 50%;
        top: 50%;

        svg {
          width: 242px;
          height: 113px;
        }

        margin-top: -52px;
        margin-left: -121px;
      }
    }

    .first-year,
    .last-year {
      flex: 0;
      font-family: "nocturne_seriflight";
      font-size: 24px;
    }
  }

  .controls {
    width: 1100px;
    height: 80px;
    position: relative;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pause-button {
      width: 30px;
      height: 45px;
    }

    .prev-button,
    .next-button {
      width: 90px;
      height: 45px;
    }

    .prev-button {
      svg {
        transform: rotate(180deg);
      }
    }

    .input-ms {
      width: 40px;
    }
  }

  .link-button-wrapper__start {
    top: 24px;
    left: 32px;
  }

  .cross-button-wrapper {
    position: absolute;
    top: 35px;
    right: 35px;
  }

  .tag-name {
    position: absolute;
    top: 12px;
    right: 120px;
    font-family: "nocturne_seriflight_italic";
    font-size: 104px;
    pointer-events: none;
    -webkit-text-stroke-width: 1px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: white;
  }
}
