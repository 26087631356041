body {
  color: white;
  background-color: black;
  height: 100vh;
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

#root {
  height: 100%;
  position: relative;
}

a {
  color: inherit;
}

* {
  box-sizing: border-box;
}
