.page-loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.6s linear;
  -webkit-transition: opacity 0.6s linear;

  &--visible {
    opacity: 1;
  }

  .background {
    $margin: -20px;
    opacity: 1;
    background-size: cover;
    position: absolute;
    left: $margin;
    top: $margin;
    bottom: $margin;
    right: $margin;

    img {
      transition: transform 0.5s linear;
      -webkit-transition: transform 0.5s linear;
    }

    &--hidden {
      img {
        transform: rotateX(90deg) scale(0.9);
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-position: center center;
      object-fit: cover;
    }
  }

  .counter {
    top: 190px;
    right: 540px;
    height: 160px;
    width: 340px;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s linear;
    -webkit-transition: opacity 0.5s linear;

    svg {
      width: 100%;
      height: 100%;
    }

    &--hidden {
      opacity: 0;
    }

    &__value {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 104px;
      font-family: "nocturne_seriflight";

      span {
        font-size: 40px;
        font-family: "IBM Plex Mono";
      }

      span:hover {
        font-family: "nocturne_seriflight_italic";
        cursor: pointer;
      }
    }
  }

  .video-wrapper {
    position: absolute;
    $width: 900px;
    top: -74px;
    right: 74px;
    width: $width;
    opacity: 0;
    transform: scaleY(0.1) translateX(200px);
    transition: opacity 0.5s linear, transform 0.5s linear;
    -webkit-transition: opacity 0.5s linear, transform 0.5s linear;

    &--visible {
      opacity: 1;
      transform: scaleY(1) translateX(0);
    }

    video {
      width: $width;
    }
  }

  .link-button-wrapper {
    position: absolute;

    &__skip {
      top: 24px;
      left: 32px;
    }
  }
}
