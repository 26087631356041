.photo-background {
  position: absolute;
  left: -100px;
  top: -100px;
  bottom: -100px;
  right: -100px;
  overflow: hidden;
  pointer-events: none;
  filter: blur(20px);

  img {
    width: 100%;
    height: 100%;
    object-position: center center;
    object-fit: cover;
  }
}
