.page-home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .images {
    display: grid;
    grid-template-columns: 200px 200px 200px 200px;
    overflow: auto;
    max-height: 600px;
    margin: 0 auto;

    img {
      max-width: 100%;
    }
  }

  .main-photo {
    position: absolute;
    top: -40px;
    left: -40px;
    right: -40px;
    bottom: -40px;

    img {
      width: 100%;
      height: 100%;
      object-position: center center;
      object-fit: cover;
    }
  }

  .link-button-wrapper {
    position: absolute;

    &__about {
      top: 24px;
      right: 32px;
    }

    &__intro {
      top: 24px;
      left: 32px;
    }
  }

  .about-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  .index-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
