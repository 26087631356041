.index {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .cross-button-wrapper {
    position: absolute;
    top: 35px;
    right: 35px;
  }

  .tags {
    $margin: 0;
    font-family: "IBM Plex Mono";
    position: absolute;
    top: $margin;
    left: $margin;
    right: $margin;
    bottom: $margin;
  }

  .canvas {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 24px;
    line-height: 24px;

    div {
      white-space: nowrap;
      cursor: pointer;
      transition: transform 0.1s ease-in-out;
      -webkit-transition: transform 0.1s ease-in-out;

      img {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        z-index: 1;
      }

      &:hover {
        z-index: 2;

        img {
          opacity: 1;
          transition: opacity 0.2s linear;
          -webkit-transition: opacity 0.2s linear;
        }
      }
    }
  }
}
