.small-screen-blocker {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background-color: #3b34fe;

  .message {
    text-transform: uppercase;
    top: 50%;
    height: 30px;
    line-height: 30px;
    margin-top: -15px;
    color: #3b34fe;
    left: 100%;
    font-size: 12px;
    text-align: center;
    font-family: "IBM Plex Mono";
    position: absolute;
    z-index: 3;
    white-space: nowrap;
    animation: fill 10s linear infinite;

    @media (min-height: 400px) {
      font-size: 20px;
    }

    @media (min-height: 600px) {
      font-size: 24px;
    }

    @keyframes fill {
      0% {
        left: calc(100% + 1px);
      }

      100% {
        left: calc(1% - 600px);
      }
    }
  }

  .ellipsis-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .ellipsis {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }
}
