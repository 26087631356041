.about-wrapper {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  backdrop-filter: blur(20px);

  &--visible {
    pointer-events: all;
    opacity: 1;
  }
}
