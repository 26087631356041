.intro {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  opacity: 0;

  &--visible {
    opacity: 1;
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #3b34fe;
    opacity: 0;
    transition: opacity 0.2s linear;
    -webkit-transition: opacity 0.2s linear;

    &--visible {
      opacity: 1;
    }
  }

  .video-wrapper {
    position: absolute;
    $width: 900px;
    // top: 0%;
    // left: 50%;
    // margin-left: -$width / 2;
    top: -74px;
    right: 74px;
    width: $width;
    opacity: 0;
    transform: scaleY(0.1) translateX(200px);
    transition: opacity 0.5s linear, transform 0.5s linear;
    -webkit-transition: opacity 0.5s linear, transform 0.5s linear;

    &--visible {
      opacity: 1;
      transform: scaleY(1) translateX(0);
    }

    video {
      width: $width;
    }
  }
}
